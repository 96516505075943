import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import {Typography} from "@mui/material"
import logo from '.././assets/img/MU LOGO.png'


function Footer(props) {
  return (
    <Box
      component="section"
      sx={{backgroundColor:'#00356B', padding: '30px'}}
    >

      <Grid container spacing={2}>
        <Grid item xs={8} md={3}>
          <Box component="div" sx={{display: 'flex', justifyContent: 'between'}}>

            <img
              src={logo}
              style={{height: '20%',width: '20%'}}
            />

            <Typography
              variant="h6"
              component="h6"
              color="#FFFFFF"
              sx={{fontFamily:'Mulish', fontWeight:'700', fontSize:'20px',lineHeight: '24px',marginTop: '10px'}}
            >
              Sam Maduka <br/>University
            </Typography>


          </Box>
        </Grid>
        <Grid item xs={4} md={3}>
          <Box component="div" sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
              variant="h6"
              component="h6"
              color="#FFFFFF"
              sx={{fontFamily:'Mulish', fontWeight:'700', fontSize:'20px',lineHeight: '24px',marginTop: '10px'}}
            >
              Features
            </Typography>

            <ul style={{listStyle: 'none',padding:"0", marginTop: '1rem'}}>

              <li>
                <a className="text-white text-decoration-none">
                  Portal
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Transcript
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Admission
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Application
                </a>
              </li>

            </ul>

          </Box>
        </Grid>
        <Grid item xs={4} md={3}>
          <Box component="div" sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
              variant="h6"
              component="h6"
              color="#FFFFFF"
              sx={{fontFamily:'Mulish', fontWeight:'700', fontSize:'20px',lineHeight: '24px',marginTop: '10px'}}
            >
              Links
            </Typography>

            <ul style={{listStyle: 'none', padding:"0", marginTop: '1rem'}}>

              <li>
                <a className="text-white text-decoration-none">
                  Portal
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Transcript
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Admission
                </a>
              </li>

              <li>
                <a className="text-white text-decoration-none">
                  Application
                </a>
              </li>

            </ul>

          </Box>
        </Grid>
        <Grid item xs={8} md={3}>
          <Box component="div" sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
              variant="h6"
              component="h6"
              color="#FFFFFF"
              sx={{fontFamily:'Mulish', fontWeight:'700', fontSize:'20px',lineHeight: '24px',marginTop: '10px'}}
            >
              Contact
            </Typography>

            <Typography
              variant="h6"
              component="h6"
              color="#FFFFFF"
              sx={{fontFamily:'Mulish', fontWeight:'300', fontSize:'15px',lineHeight: '24px',marginTop: '10px'}}
            >
              No 35 Ajose Adeogun Street Utako ABija
            </Typography>


          </Box>
        </Grid>
      </Grid>

      <Box
        component='div'
        sx={{color: '#FFFFFF', textAlign:'center', marginTop:'2rem'}}>
        © 2006-2020. Sam Maduka University.
      </Box>

    </Box>
  );
}

export default Footer;
